<script>
import BulmaStyles from './BulmaStyles.svelte';
import MaterializeStyles from './MaterializeStyles.svelte';
import Router from './components/Router.svelte';
</script>
<BulmaStyles />
<MaterializeStyles />
<svelte:head>
	<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
	<meta name="robots" content="noindex, nofollow">
	<meta name="charset" content="UTF-8">
	<title>Yuryor</title>
</svelte:head>
<Router />