import { writable } from 'svelte/store';

function Controller() {
	turpial.controller.routes.set();

	const mvc = turpial.app;
	const { subscribe, set, update } = writable({
		controller_name: mvc.controller_name||"",
		action_name: mvc.action_name||"",
		parameters: mvc.parameters||[],
		path: mvc.path||"",
		props: {},
	});


	const route = {
		subscribe,
		eventsAdded: false,
		events: [],
		RoutesAdded: [],
		addRouterEvent: function(event){
			if(typeof event === "undefined"){ event = "success"; }
			if(event === "force-error"){
				setTimeout(()=>{
					update(function(data){
						data.props = data.props||{};
						data.props.RoutesAdded = data.props.RoutesAdded||[];
						data.props.RoutesAdded = "forced";
						return data;
					})
				}, 350)
				return;
			}
			update(function(data){
				data.props = data.props||{};
				data.props.RoutesAdded = data.props.RoutesAdded||[];
				data.props.RoutesAdded.push(event);
				return data;
			})
		},
		addEvent: function(fun,id){
			id = id||turpial.random_string(5);
			const app = this;
			this.events.push({fun: fun, id: id});
			fun();
		},
		renderEvents: function(){
			this.events.map(function(event){
				if(typeof event.fun === "function"){
					event.fun(turpial.app);
				}
			})
		},
		redirect: function(path, event){
			return this.route(path, event, "replaceState");
		},
		getCurrent: function(){
			let path = "";
			update(( data )=>{
				path = data.path;
				return data;
			})
			return path;
		},
		to: function(to, getPath){
			getPath = getPath ?? false;
			let current = this.getCurrent();
			let path = current;
			if(typeof to === "string" || typeof to === "number"){
				current = current.slice(-1) === "/" ? current+"" : current+"/";

				if(to.search("../") >= 0){
					let undos = to.split("../");
					let numbers = 0;
					let realPath = function(un){
						return un.pop();
					}
					undos.map(( undo )=>{
						if(undo === ""){ numbers++ }
					})				
					path = path.split("/");
					
					for (let index = 0; index <= numbers; index++) {						
						path.pop();
					}
					path = path+"/"+realPath(undos);
					
				}else{
					path = current+`${to}`
				}	
			}
			if(getPath === true){
				
				return path;
			}
			this.route(path);
		},
		route: function(path, event, state){
			const app = this;
			state = state||"pushState"
			turpial.router({
				path: path,
				type: state
			})
			turpial.controller.routes.set({
				RoutesAdded: app.RoutesAdded
			});
			update(function(data){
				return turpial.app;
			})

			app.renderEvents();
			return "redirect";
		},
		getPath: function(path){
			const app = this;
			turpial.controller.routes.set({
				RoutesAdded: app.RoutesAdded
			});
			let getRoute = function(){
				return path;
			}

			let host = turpial.host;
			let route = getRoute();
			if(host.slice(-1) !== "/"){ host = host+"/" };
			if(route.slice(-1) === "/"){ route = route.slice(-1, 0) };

			return host + route;
		},
		onchange: function(){
			const app = this;
			if(this.eventsAdded === true){
				app.renderEvents();
				return turpial.app;
			}

			this.eventsAdded = true;

			window.addEventListener('popstate', function() { 
				turpial.controller.routes.set({
					RoutesAdded: app.RoutesAdded
				});
				update(function(){
					return turpial.app;
				});		
				app.renderEvents();
			});

			app.renderEvents();
			return turpial.app;
		}
	}
	route.onchange();
	
	return route;
}


export const page = Controller();