import { page } from './turpial.route.store.js';
const url = ()=> turpial.app;
const base = url().controller_name;
const baseAction = `${url().controller_name}/${url().action_name}`;



const Routes = {
	index: {
		type: "public",
		_render: ()=> import("./views/Index.svelte").then((r)=> r),
	},
	superuser: {
		type: "private",
		_render: ()=>{ page.redirect("superuser/dashboard") },
		login: {
			_render: ()=> import("./views/private/superuser/Login.svelte").then((r)=> r),
		},
		dashboard: {
			type: "private",
			clients: {
				_render: ()=> import("./views/private/superuser/dashboard/Clients.svelte").then((r)=> r),
			},
			_render: ()=> import("./views/private/superuser/Dashboard.svelte").then((r)=> r),
		},
	},
	errors: {
		type: "public",
		404: {
			_render: ()=> import("./views/errors/404.svelte").then((r)=> r),
		}
	}
};




















const currentPath = ()=>{
	return `${baseAction}/${url().parameters.join("/")}`
}

function on_parameter(parameter, Redirection, onTrue, onFalse){
	Redirection = Redirection||function(){};
	onTrue = onTrue||function(){};
	onFalse = onFalse||function(){};
	if(typeof url().parameters[parameter] !== "undefined"){
		Redirection();
		return onTrue()
	}else{
		return onFalse()
	}
}

function paramaters(results, params){
	params = params||[];
	results.params = params;
	return results;
}


export default Routes;
