// bulma docs https://dev.to/1n0t/creacion-de-un-proyecto-svelte-bulma-sass-1o8i
import App from '../App.svelte';
//import "../components/Elements/Materialize/materialize.scss";
import "../styles.bulma.scss";

let app = document.querySelector(".svelteApp");
app = turpial.mount(app, `<div class="svelte-app-child index-module"></div>`);
app = new App({
	target: app,
});

//export default app;