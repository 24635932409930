<script>
import { page } from '../turpial.route.store.js';
import Routes from '../routes.js';
import Loader from '../components/Loader.svelte';


export let on_controller = "";
export let on_action = "";
export let on_parameters = "";

const reserved_words = /_render|_limit_params/;

export let path = "";
let allFails = true;
let routes = path.split("/");
let controller_name = "";
let action_name = "";
let parameters = [];
routes.map(( selector, key )=>{
	if(key === 0){
		controller_name = selector;
		return;
	}
	if(key === 1){
		action_name = selector;
		return;
	}
	parameters.push(selector);
})
if(controller_name === ""){
	controller_name = "index";
}
let views = {
	controller_name: controller_name,
	action_name: action_name,
	parameters: parameters,
}

views = `${views.controller_name}/${views.action_name}/${views.parameters.join("/")}`;
views = views.split("///").join("/");
views = views.split("//").join("/");


if(path !== ""){
	on_controller = controller_name;
	on_action = action_name;
	on_parameters = parameters;
}

function showView(structure){
	if(structure.path.search(reserved_words) >= 0){
		console.error("Reserved Words Found.")
		return Routes.errors["404"]._render();
	}
	let view = null;
	if(typeof Routes[structure.controller_name] === "object"){
		if(typeof Routes[structure.controller_name][structure.action_name] === "object"){
			view = Routes[structure.controller_name][structure.action_name];
			let action = view;
			let limit_params = turpial.un(action._limit_params, true);
			if(structure.parameters.length > 0){
				var errorParams = false;
				structure.parameters.map(( param )=>{
					if(typeof view[param] !== "undefined"){
						view = view[param];
					}else{
						errorParams = true;
					}
				})
				if(limit_params === true && errorParams === true){
					return Routes.errors["404"]._render();
				}
			}


		}else{
			if(structure.action_name === ""){
				view = Routes[structure.controller_name];
			}
		}
	}

	if(view === null){
		return Routes.errors["404"]._render();
	}
	return view._render();
}

</script>
{#await showView($page)}
		<Loader status="show" />
	{:then module}
	{#if typeof module !== "undefined" && module !== false && module !== null}
			<!--<RouterEvent event="success" controller={$page.controller_name} />-->
			<svelte:component this={module.default}/>
				<slot></slot>
				<Loader status="hide" />
	{/if}
{/await}